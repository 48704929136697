<template>
  <section class="flat-or-sloping-ground">
    <section class="hero">
      <div class="hero-container">
        <h1>Construire sa maison sur un terrain plat ou en pente ?</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/flat-or-sloping-ground.jpg"
        alt="Construire sa maison sur un terrain plat ou en pente ?"
      />
    </div>
    <article class="grid-container article">
      <div class="article-card">
        <p>
          Souvent moins chers à l’achat, les terrains qui présentent un fort dénivelé nécessitent
          des opérations de gros œuvre dont la complexité technique est susceptible d’impacter, en
          aval, <router-link to="/projet-construction-maison/budget">le coût de construction d’une maison</router-link>.
        </p>
        <p>
          Au-delà de son prix de départ, généralement plus abordable – à surface égale – qu’un site
          sans déclivité apparente, ce type de parcelle présente néanmoins des avantages : il offre,
          en fonction des modelés topographiques, une vue imprenable sur une vallée, un cours d’eau
          ou une zone urbaine campée au fond d’une dépression, et constitue, selon la direction du
          versant sur lequel on se trouve, une protection physique contre les vents opposés. Un
          relief bien exploité autorise aussi des aménagements paysagers plus.
        </p>
      </div>
      <div class="article-ground-types fit-content">
        <h2>Maison « semi-enterrée », surélevée ou en gradins</h2>
        <div class="article-ground-types-text">
          <p>
            Parmi les trois techniques de construction mises en œuvre sur les terrains en pente, la
            maison dite « encastrée » ou « semi-enterrée » a l’avantage, par rapport aux autres, de
            posséder des vertus bioclimatiques : les parties d’habitat intégrées dans le dénivelé
            protent de l’inertie thermique du sous-sol pour y capter de l’air plus chaud en hiver et
            de l’air plus frais en été.
          </p>
          <p>
            En fonction de l’importance du relief et de ses caractéristiques géologiques, les
            constructeurs privilégient d’autres formes de structures, tantôt une surélévation grâce
            à un système de pilotis (ou en belvédère), tantôt une édication en gradins (avec des
            niveaux décalés pour adapter l’architecture de l’ouvrage au prol de la côte).
          </p>
          <p>
            Ceci dit, il est évident que la nature des travaux à efectuer sur ce type de surface
            inclinée alourdit le budget par rapport à un projet mené sur un terrain parfaitement
            plane et sans accidents qui entraîne des interventions de moindre ampleur.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'flat-or-sloping-ground',
};
</script>

<style lang="sass">
.flat-or-sloping-ground
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-card
      display: flex
      flex-direction: column
      align-items: center
      background-color: $white
      border-radius: $global-border-radius
      box-shadow: $drop-shadow
      padding: 80px
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        @include hyperlink
        text-decoration: none
        font-size: inherit
        font-weight: normal
    &-ground-types
      margin: 4.5rem auto 0 auto
      h2
        text-align: center
        color: $primary
      &-img
        margin-top: 4.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%
    max-width: 950px
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-card
        padding: 1rem 1.75rem
        margin: 2.5rem auto 0 auto
      &-ground-types
        margin: 3rem auto 0 auto
        &-text
          margin-top: 1.5rem
</style>
